<template>

<app-content :loading="is.loading" :fullheight="true">

	<app-content-head title="Reports" :is-app="true" />

	<app-content-body :no-padding="true" class="report" v-if="!is.loading">

		<com-game :game="item.game">

			<app-input-select :icon="(is.saving) ? 'loading' : false" v-model="model.status" :theme="$constants.checkout.report.statusTheme[model.status]" validation="$v.model.status" :options="$constants.checkout.report.statusName" />

		</com-game>

		<app-widget-chat :no-head="true" :id="item.id" url="messages" class="report-content" />

		<div class="report-actions">

			<app-button :disabled="is.saving" text="Close" theme="grey" v-on:click="onCloseClick" />

		</div>
		
	</app-content-body>

</app-content>

</template>

<script>

import Vue from 'vue'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

	components: {
		'com-game': () => import('./common/Game')
	},

	data: function() {

		return {
			is: {
				failed: false,
				loading: true,
				checking: false,
				saving: false
			},
			item: {},
			model: {
				game: 0,
				status: 1
			}
		}

	},

	validations: {
		model: {
			game: {
				required
			}
		}
	},

	created: function() {

		this.load()

	},

	watch: {

		status: function() {

			this.save()

		}

	},

	computed: {

		status: function() {

			return this.model.status

		}

	},

	methods: {

		onCloseClick: function() {

			this.$router.push({
				name: 'Reports.Dashboard'
			})

		},

		save: function() {

			this.is.saving = true

			this.$api.put(['reports/report', this.$route.params.id], this.model).then(function() {

				this.is.saving = false

				this.$notify({
					message: 'Status change saved'
				})

			}.bind(this))

		},

		load: function() {

			this.is.loading = true

			this.$api.get(['reports/report', this.$route.params.id]).then(function(json) {

				Vue.set(this, 'model', json.model)
				Vue.set(this, 'item', json.item)

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.report {
	background-color: #fff;
	display: flex;
	flex-direction: column;
}

.report-content {
	flex-grow: 1;
	padding: 0px 10px 10px 10px;
	margin-bottom: 4px;
}

.report-actions {
	flex-shrink: 0;
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	background-color: #384352;
}

.report-actions >>> .button {
	width: calc(50% - 10px);
}

</style>